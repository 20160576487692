/** @module Timer */

/**
 * Creates a timer that still works in a hidden tab
 *
 * @param {Function} callback
 * @param {Number} interval in ms, how often we need to call callback
 * @param {Boolean} skipFirstRound whether to skip the first execution or to wait for at least 1 interval
 * @return {Function} the cancel function
 */
export const timer = function audioTimerLoop(callback, interval, skipFirstRound = true) {
    // AudioContext time parameters are in seconds
    const dt = interval / 1000;

    const aCtx = new AudioContext();
    // Chrome needs our oscillator node to be attached to the destination
    // So we create a silent Gain Node
    const silence = aCtx.createGain();
    silence.gain.value = 0;
    silence.connect(aCtx.destination);

    let stopped = false;

    function onOSCend() {
        if (stopped) {
            return;
        }
        const osc = aCtx.createOscillator();
        osc.onended = onOSCend;
        osc.connect(silence);
        osc.start(0);
        osc.stop(aCtx.currentTime + dt);
        if (!skipFirstRound) {
            callback(aCtx.currentTime);
        }
        skipFirstRound = false;
    }

    // to start the timer after having returned the stop function
    setTimeout(onOSCend);

    // return a function to stop our loop
    return function() {
        stopped = true;
        if (aCtx.state === 'running') {
            aCtx.close();
        }
    };
};
