// Boot sequence:
//   1. Get user session
//   2. Provide maestro.config
//   3.a Load event navs
//   3.b Load fstg.css, libfstg.js and frontstage config docs
//   4. Provide fstg.config

if ("aws-dev" !== 'dev') {
    const isInAnIframe = (() => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    });

    if (isInAnIframe()) {
        window.parent.location = window.location;
    }
}

import moment from 'moment-timezone';
import 'moment/min/locales.min';
import { get } from 'lodash';

import { register } from './utils/errorReporting';
import { parseTheme, normalizeNavs } from './utils/webapp-skeleton';
import { getEventIconUrl } from './utils/assets';
import { getUserSession } from './services/authService';

const $injector = angular.injector([ 'ng' ]);
const $http = $injector.get('$http');
const $q = $injector.get('$q');
const $timeout = $injector.get('$timeout');
const $httpParamSerializer = $injector.get('$httpParamSerializer');

const ENV = "aws-dev";
const VERSION = "1.144.0";
let APP_RESOURCE_PATH;

if ("aws-dev" === 'production') {
    APP_RESOURCE_PATH = `/webapp/static/${VERSION}`;
} else {
    APP_RESOURCE_PATH = '';
}

angular.module('maestro.config', []);

const provideMaestroConfig = response => {
    const {
        data: { event, user, branding, version },
        prefix
    } = response;
    const title = event.name || (event.description || {}).en;
    document.title = `${title} - SpotMe`;

    // register branding in error reporting tool
    register(branding || event.branded_app, event._id);
    angular
        .module('maestro.config')
        .constant('ENV', ENV)
        .constant('VERSION', VERSION)
        .constant('EID', event._id)
        .constant('EVENT', event)
        .constant('APP_RESOURCE_PATH', APP_RESOURCE_PATH)
        // future-proof
        .constant('PID', user._id)
        .constant('ACTIVATED_PERSON', user)
        .constant('BRANDING', branding)
        .constant('PLATFORM', 'web')
        .constant('ANALYTICS_PLATFORM', 'web')
        .constant('ROUTE_PREFIX', prefix || '')
        .constant('BACKEND_VERSION', (version || {}).backend)
        .constant('WHITE_LABEL_DOMAIN', (event || {}).white_label_domain)
        .constant('NO_CBL', false); // This provider is needed for compatibility for the new couchless native app

    return event;
};

const appScriptUrl = (eid, path) => `/api/v1/eid/${eid}/appscripts/${path}`;
const runAppScript = ({ eid, path, params, lang, timezone }) =>
    $http({
        method: 'POST',
        url: appScriptUrl(eid, path),
        data: {
            timezone, lang, params
        }
    });

const initAppScript = ({ eid, path, params, lang, timezone }) => {
    angular
        .module('maestro.config')
        .constant('APPSCRIPT_CONFIG', {})
        .constant('APPSCRIPT_BLOB', null);
    return runAppScript({ eid, path, params, lang, timezone });
};

const setTimezone = (settings, event) => {
    let timezone = moment.tz.guess();

    if (!settings.date || settings.date.timezone !== 'device') {
        timezone = event.timezone;
        moment.tz.setDefault(timezone);
    }

    console.log('[maestro] party is in...', timezone);
    return timezone;
};

const loadConfig = (event, cacheBust) => {
    return $http.get(
        `/api/v1/eid/${event._id}/webapp/bootstrap-resources${cacheBust ? `?_=${ Date.now() }` : ''}`
    ).then(({ data }) => {
        const normNavs = normalizeNavs(data.navs);
        const timezone = setTimezone(data.settings, event);
        const settings = data.settings;
        const actions = data.actions;

        angular
            .module('maestro.config')
            .constant('I18N', data.i18n)
            .constant('SETTINGS', data.settings)
            .constant('ACTIONS', data.actions || {})
            .constant('BEHAVIOURS', data.behaviours || {})
            .constant('PERSON_DYNAMIC_EXTENSIONS', data.personDynamicExtensions)
            .constant('THEME', parseTheme(data.theme, event))
            .constant('EVENT_NAVS', normNavs)
            .constant('EVENT_TYPE', 'event')
            .constant('METADATA', data.metadata)
            .constant('NAV_BAR_CONFIG', normNavs.nav_navigation_bar || { enabled: false })
            .constant('USE_APPCUES', (event.webapp || {}).load_appcues)
            .constant('ANALYTICS_ENDPOINT', data.analytics_endpoint);

        const lang = angular.injector([
            'ng', 'maestro.config', 'i18n'
        ]).get('$i18n').lang;

        moment.locale(lang);
        console.log('Locale', moment.locale());

        const setHomeNav = nav => {
            nav = nav || data.navs.nav_home;
            nav.$is_home = true;
            angular.module('maestro.config').constant('HOME_NAV', nav);
        };

        let home = (data.config.home || {}).ds;
        const applyHomeFallback = () => setHomeNav(normNavs.nav_home);

        // check in settings if home can be defined by the settings
        const {
            fp_type: fpType,
            fp_ext_id: fpExtId
        } = settings['home-launcher'].item || {};
        if (fpType && fpExtId) {
            if (fpType === 'nav') {
                const nav = normNavs[fpExtId];
                if (nav) {
                    const customNavAction = actions[`fp_type:nav:${fpExtId}`];
                    // local_resolution is for navs which will soon have no custom action
                    // but still need to be there for backwards compatibility
                    if (!customNavAction || customNavAction.local_resolution) {
                        return setHomeNav(nav);
                    }
                }
            }
        }

        if (!home || home.type !== 'javascript' || !home.source || !home.source.path) {
            setHomeNav(data.navs.nav_home);
            home = data.navs.nav_home.ds;
        }

        return initAppScript({
            eid: event._id,
            path: home.source.path,
            params: home.source.params,
            timezone,
            lang
        }).then(
            ({ data: { status, response } }) => {
                if (status === 'error') applyHomeFallback();
                setHomeNav(response || data.navs.nav_home);
            },
            applyHomeFallback
        );
    });
};

const loadWebinarConfig = event => {
    return $http.get(`/api/v1/eid/${event._id}/webapp/webinar-resources`).then(({ data }) => {
        setTimezone(data.settings, event);

        angular
            .module('maestro.config')
            .constant('I18N', data.i18n)
            .constant('SETTINGS', data.settings)
            .constant('ACTIONS', {})
            .constant('BEHAVIOURS', {})
            .constant('THEME', parseTheme(data.theme, event))
            .constant('EVENT_NAVS', {})
            .constant('EVENT_TYPE', 'webinar')
            .constant('NAV_BAR_CONFIG', { enabled: false })
            .constant('USE_APPCUES', (event.webapp || {}).load_appcues)
            .constant('APPSCRIPT_CONFIG', {})
            .constant('APPSCRIPT_BLOB', null)
            .constant('ANALYTICS_ENDPOINT', data.analytics_endpoint)
            .constant('HOME_NAV', { $is_home: true });

        const lang = angular.injector([
            'ng', 'maestro.config', 'i18n'
        ]).get('$i18n').lang;

        moment.locale(lang);
        console.log('Locale', moment.locale());
    });
};

const applyEventBranding = (event) => {
    const iconUrl = getEventIconUrl(event);
    const el = angular.element;
    el('head')
        .append(el(`<link rel="icon" href="${iconUrl}"/>`))
        .append(el(`<link rel="apple-touch-icon" href="${iconUrl}"/>`))
        .append(el(`<meta name="msapplication-square70x70logo" content="${iconUrl}"/>`))
        .append(el(`<meta name="msapplication-square150x150logo" content="${iconUrl}"/>`));
};

const bootReady = function() {
    const requestTimeout = $q.defer();
    const cancelRequest = $timeout(() => {
        console.warn(
            '[maestro] Failed to get session in a timely manner. Retrying.'
        );
        requestTimeout.reject();
        boot();

        // still not a controller
        angular
            .element('#app-preloader .progress-bar')
            .addClass('finished-with-error');
        angular.element('#app-preloader .preloader-error').show();
        angular.element('.global-blocking-task-guard').hide();
    }, 60 * 1000 + Math.round(Math.random() * 1000));

    $timeout.cancel(cancelRequest);
    angular.element('#app-preloader, #unsupported').remove();

    angular.element(document).ready(() => {
        angular.bootstrap(document, [ 'maestro' ], { strictDi: true });
        // Expose appscript runner in console for debugging purpose
        window.debugAppscript = function(scriptName, params = {}) {
            angular
                .element(document.body)
                .injector()
                .get('databaseService')
                .runAppScript(scriptName, params)
                .then(e => console.log(e.data));
        };
    });
};


const boot = async function() {
    const requestTimeout = $q.defer();
    const redirectToLogin = (branding, reason, redirect) => {
        // This check will prevent endless redirect loops.
        if (window.location.href.indexOf('/welcome') !== -1) {
            return;
        }

        let url = "https://webapp.spotme-dev.com" + '/welcome';

        if (branding) {
            url += `/${branding}`;
        }

        let query = {};
        if (reason) {
            query.reason = reason;
        }
        if (redirect) {
            query.redirect = redirect;
        }
        if (reason || redirect) {
            url += `?${$httpParamSerializer(query)}`;
        }

        window.location.href = url;
    };

    const session = await getUserSession({ timeout: requestTimeout.promise }, $http)
        .catch(errorResponse => {
            const error = errorResponse.data || {};
            const branding = error.branding;

            if (errorResponse.status === 400 && error.error === 'archived event') {
                redirectToLogin(branding, 'archived');
            } else {
                redirectToLogin(branding, null, window.location.pathname);
            }

            console.error('[Bootloader] Boot interrupted:', error);
            throw new Error('Failed to get session');
        });
    const event = await provideMaestroConfig(session);
    if (event.is_webinar) {
        await loadWebinarConfig(event);
    } else {
        await loadConfig(event, get(session, 'data.user.is_team_member'));
    }
    await bootReady();
    applyEventBranding(event);
};

console.log('[maestro] booting v' + "1.144.0", "aws-dev");

// In karma/jasmine env we can't mock HTTP requests that fetch the session
// because the bootloader itself is not a module, which is why we skip
// the whole boot process alltogether and expect each spec to provide it's own config
if ("aws-dev" !== 'dev') {
    boot();
} else {
    if ('__karma__' in window) {
        console.log('[bootloader] test env detected, skipping boot procedures');
    } else {
        boot();
    }
}
