import { chain } from 'lodash';

const BLOCKS_PROTO = 'fstg-blocks://';
const SOURCES_PROTO = 'fstg-sources://';
const FSTG_SOURCE_HANDLER = 'webapp/fstg-source';
const FSTG_RESOURCE_HANDLER = 'webapp/fstg-resource';
const HARDCODED_INCLUDES = [
    'fstg-sources://lib/d3.v3.min.js',
    'fstg-sources://lib/d3.cloud.layout.js',
    // session-registration-with-restrictions is an override, it needs to be loaded after the original
    'fstg-blocks://session-registrations/block.js',
    'fstg-blocks://session-registration-with-restrictions/block.js',
];

/* @ngInject */
function ResourceLoaderService(
    COMPILED_FSTG_ASSETS,
    $q,
    $document,
    $templateCache,
    handlerService
) {
    const doc = $document[0];
    let resourceLoaded = false;

    const logs = [];
    const differLog = (type, message, param) =>
        logs.push({ type, message, param });
    const log = () => {
        if ("aws-dev" === 'dev') {
            logs.forEach(({ type, message, param }) =>
                console[type](message, param)
            );
            logs.splice(0);
        }
    };

    const normalizeUri = function(uri = '') {
        uri = uri.trim();

        if (uri.indexOf(BLOCKS_PROTO) === 0) {
            const [ block, ...resource ] = uri
                .replace(BLOCKS_PROTO, '')
                .split('/');
            return handlerService.generateBackendUrl(FSTG_RESOURCE_HANDLER, {
                params: {
                    attachment: `${block}/${resource.join('/')}`
                }
            });
        }

        if (uri.indexOf(SOURCES_PROTO) === 0) {
            return handlerService.generateBackendUrl(FSTG_SOURCE_HANDLER, {
                params: {
                    attachment: `${uri.replace(SOURCES_PROTO, '')}`
                }
            });
        }

        return uri;
    };
    const normalizeUriList = uris =>
        chain([ uris ])
            .flatten()
            .compact()
            .uniq()
            .map(normalizeUri)
            .value();

    // / Resource injection
    const resourceInjectors = {
        css(path, body) {
            let el = doc.createElement('style');
            el.type = 'text/css';
            const cssText = `${body}\n/* sourceURL: ${path}*/`;
            if (el.styleSheet) {
                el.styleSheet.cssText = cssText;
            } else {
                el.appendChild(document.createTextNode(cssText));
            }
            doc.head.appendChild(el);
            differLog('log', '[resourceLoader] css injected', path);
        },

        js(path, body) {
            let el = doc.createElement('script');
            el.type = 'text/javascript';
            el.text = `${body}\n//# sourceURL=${path}`;
            doc.head.appendChild(el);
            differLog('log', '[resourceLoader] js injected', path);
        },

        html(path, body) {
            $templateCache.put(normalizeUri(path), body);
            differLog('log', '[resourceLoader] html injected', path);
        }
    };

    let injectResource = function(body, path) {
        const type = path.split('.').slice(-1)[0];
        const resourceInjector = resourceInjectors[type];
        if (!resourceInjector) {
            differLog('error', '[resourceLoader] Unknown resource injector', type);
            return;
        }

        resourceInjector(path, body);
    };

    const vendorSort = function(a, b) {
        const vendorA = a.includes('/vendor/');
        const vendorB = b.includes('/vendor/');
        const aFileName = a.replace(/\.[^.]*$/, '');
        const bFileName = b.replace(/\.[^.]*$/, '');
        // we put non hardcoded files at Infinity to make sorting easier
        const aIndex = HARDCODED_INCLUDES.includes(a) ? HARDCODED_INCLUDES.indexOf(a) : Infinity;
        const bIndex = HARDCODED_INCLUDES.includes(b) ? HARDCODED_INCLUDES.indexOf(b) : Infinity;
        if (vendorA && !vendorB) {
            return -1;
        }
        if (vendorB && !vendorA) {
            return 1;
        }
        if (aIndex < bIndex) {
            return -1;
        }
        if (aIndex > bIndex) {
            return 1;
        }
        if (aFileName < bFileName) {
            return -1;
        }
        if (aFileName > bFileName) {
            return 1;
        }
        return 0;
    };

    // this function needs to be async because it has .then() calls in the lib-frontstage packages
    const injectResources = async () => {
        if (resourceLoaded || !COMPILED_FSTG_ASSETS) {
            return;
        }
        resourceLoaded = true;

        for (const resource of Object.keys(COMPILED_FSTG_ASSETS).sort(vendorSort)) {
            injectResource(COMPILED_FSTG_ASSETS[resource], resource);
        }

        console.groupCollapsed('[resourceLoader] loading resources');
        log();
        console.groupEnd();
    };

    return {
        normalizeUri,
        normalizeUriList,
        loadBlockResources: injectResources,
        loadPageResources: injectResources,
    };
}

angular
    .module('frontloader')
    .service('resourceLoaderService', ResourceLoaderService);
