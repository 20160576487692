import { extend, isEmpty } from 'lodash';
import cleanMagicProperties from '../../utils/clean-magic-properties';

export default class OpenDocument {
    /* @ngInject */
    constructor(
        ACTIONS,
        metaService,
        databaseService,
        progressBarService,
        metricsService,
        $anchorScroll
    ) {
        this.ACTIONS = ACTIONS;
        this.metaService = metaService;
        this.databaseService = databaseService;
        this.progressBarService = progressBarService;
        this.metricsService = metricsService;
        this.$anchorScroll = $anchorScroll;
    }

    openDocument(arg, config = {}) {
        const {
            ACTIONS,
            databaseService,
            progressBarService,
            metricsService
        } = this;
        let deferred;

        if (typeof arg === 'string') {
            arg = { id: arg };
        }

        arg = cleanMagicProperties(arg);
        config = cleanMagicProperties(config);

        if (arg.id || arg._id) {
            deferred = databaseService.docWithId(arg.id || arg._id);
        } else if (arg.fp_ext_id && arg.fp_type) {
            deferred = databaseService.docOfTypeAndExtId(
                arg.fp_type,
                arg.fp_ext_id
            );
        } else {
            console.error(
                "[navService/openDocument] can't determine which document to open",
                arg
            );
            return;
        }

        progressBarService.startTask();
        return deferred
            .then(doc => {
                const fpType = doc.fp_type;
                const fpTypeAction = `fp_type:${fpType}`;
                const customAction = ACTIONS[fpTypeAction];
                if (customAction) {
                    console.log(
                        '[navService/openDocument] offloading action to app script',
                        doc
                    );
                    arg.id = doc._id;
                    arg.session_id = metricsService.sessionId;

                    return databaseService.runAppScript(
                        'actions/open-document',
                        extend({}, arg, config)
                    );
                }

                metricsService.trackAnalytics({
                    name: 'view',
                    document_id: doc._id,
                    document_type: fpType
                });

                const source = arg.source;
                const openMethod =
                    source === 'spotman' || source === 'home'
                        ? 'presentNav'
                        : 'openNav';
                const navParams = {
                    nav_id: 'nav_fstg',
                    page_id: arg.fstgPage || doc.fp_fstg_page || fpType,
                    page_params: {
                        model_id: doc._id,
                        fp_type: fpType,
                        model_ext_id: doc.fp_ext_id
                    }
                };

                if ('_parent_nav_id' in config) {
                    navParams._parent_nav_id = config._parent_nav_id;
                }

                if (!isEmpty(doc.video_breakout_id) && !doc.video_breakout_cancel) {
                    navParams.video_breakout_id = doc.video_breakout_id;
                }

                if (config.navParams) {
                    extend(navParams, config.navParams);
                }

                const actionSpec = {
                    method: openMethod,
                    params: navParams
                };
                this.metaService.callServiceMethod(
                    actionSpec.service,
                    actionSpec.method,
                    [ actionSpec.params ]
                );
                this.$anchorScroll();
            })
            .finally(() => progressBarService.finishTask());
    }
}
