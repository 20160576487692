class ProgressBarService {
    /* @ngInject */
    constructor($rootScope) {
        this.$rootScope = $rootScope;
        this.activeTasks = 0;
        this.activeBlockingTasks = 0;
    }

    hasActiveTasks() {
        return this.activeTasks > 0 || this.activeBlockingTasks > 0;
    }

    hasBlockingTasks() {
        return this.activeBlockingTasks > 0;
    }

    notify() {
        this.$rootScope.$emit('progress:activity', this.hasActiveTasks());
    }

    startTask() {
        this.activeTasks += 1;
        this.notify();
        return this.activeTasks;
    }

    finishTask() {
        this.activeTasks = this.activeTasks > 0 ? this.activeTasks - 1 : 0;
        this.notify();
        return this.activeTasks;
    }

    startBlockingTask() {
        console.log('[ProgressBarService] started a blocking task');
        this.activeBlockingTasks += 1;
        this.notify();
        return this.activeBlockingTasks;
    }

    finishBlockingTask() {
        if (!this.activeBlockingTasks) {
            this.notify();
            return;
        }

        this.activeBlockingTasks -= 1;

        if (this.activeBlockingTasks === 0) {
            console.log(
                '[ProgressBarService] all blocking tasks have finished'
            );
        }

        this.notify();

        return this.activeBlockingTasks;
    }
}

angular
    .module('maestro.services')
    .service('progressBarService', ProgressBarService);
