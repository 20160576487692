import { each, filter, pluck, debounce, partition } from 'lodash';

/* @ngInject */
function LazyAssetGridDirective($window, $filter, loadAssetImg, EID, $http) {
    const assetUrl = $filter('assetUrl');
    const $$window = angular.element($window);

    const checkIfAssetsExist = function(assets) {
        const isExternalPath = path => /^(?:[^/]*\/\/.+|\/api)/.test(path);
        const [ externals, internals ] = partition(assets, ([ path ]) =>
            isExternalPath(path)
        );

        // load externals directly
        for (const [ path, el ] of externals) {
            loadAssetImg(el, path);
        }

        assets = internals
            .filter(i => i[0] !== null)
            .map(([ path, el ]) => [ path.split('/')[0], { path, el } ]);

        if (!assets.length) return;

        const ids = pluck(assets, '0').join(',');
        $http
            .get(`/api/v1/eid/${EID}/webapp/doc-list?ids=${ids}`)
            .then(({ data }) => {
                each((data.rows || data.docs || []).reverse(), (row, n) => {
                    const assetsToUpdate = filter(
                        assets,
                        ([ assetId ]) =>
                            !row.error &&
                            (assetId === row.id || assetId === row._id)
                    );
                    each(assetsToUpdate, ([ , /* id */ { el, path } ]) => {
                        if (path && path.length) {
                            setTimeout(
                                () => loadAssetImg(el, assetUrl(path)),
                                (n + 1) * 50
                            );
                        }
                    });
                });
            });
    };

    const isAnyPartOfElementInViewport = function(el) {
        const rect = el.getBoundingClientRect();
        // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
        const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;
        const windowWidth =
            window.innerWidth || document.documentElement.clientWidth;

        // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
        const vertInView =
            rect.top <= windowHeight && rect.top + rect.height >= 0;
        const horInView =
            rect.left <= windowWidth && rect.left + rect.width >= 0;

        return vertInView && horInView;
    };

    const link = function(scope, elem, attrs) {
        const checkScroll = function() {
            console.groupCollapsed(
                '[LazyAssetGridDirective] checking grid',
                elem[0]
            );
            const assetQuery = '[lazy-background-asset-src], [lazy-asset-src]';
            const assetHeightAttr = attrs.assetsInBg
                ? 'clientHeight'
                : 'height';
            const assets = elem.find(assetQuery);
            console.log('Found %d assets', assets.length, assets);

            if (!assets.length) {
                console.groupEnd();
                return;
            }

            const gridOffset = elem.offset();

            const firstElInView = document.elementFromPoint(
                gridOffset.left + 1,
                gridOffset.top + 1
            );
            const firstAsset = $(firstElInView).find(assetQuery);
            const firstAssetIndex = firstAsset.length
                ? assets.index(firstAsset)
                : 0;

            console.log('Starting scan with', firstAssetIndex, firstAsset[0]);

            let firstInViewFound = false;
            const assetHeight = assets[0][assetHeightAttr];
            const assetsToCheck = assets.slice(firstAssetIndex);
            const assetsToLoad = [];
            for (let i = 0, n = assetsToCheck.length; i < n; i += 1) {
                const asset = assetsToCheck[i];
                if (asset.className.indexOf('asset-loaded') !== -1) {
                    console.log('skipping an already loaded asset');
                    continue;
                }
                const $asset = $(asset);
                const assetOffset = $asset.offset().top - assetHeight;
                if (assetOffset < 0) {
                    console.warn('assetOffset < 0', asset);
                }

                const isInView = isAnyPartOfElementInViewport(asset);

                if (!firstInViewFound && isInView) {
                    firstInViewFound = true;
                }
                if (!isInView && firstInViewFound) {
                    console.log('Last element', asset);
                    break;
                }
                if (!isInView) {
                    continue;
                }

                const assetSrc =
                    asset.getAttribute('lazy-background-asset-src') ||
                    asset.getAttribute('lazy-asset-src');
                assetsToLoad.push([ assetSrc, $asset ]);
            }

            console.log('Assets to load', assetsToLoad);
            console.groupEnd();

            if (assetsToLoad.length)
                checkIfAssetsExist(assetsToLoad);
        };
        const _checkScroll = debounce(checkScroll, 500);

        // const removeScopeEventListener = scope.$on('checkAssets', _checkScroll);
        elem.on('ready', _checkScroll);
        $$window.on('scroll', _checkScroll);
        $$window.on('resize assets:load', _checkScroll);
        scope.$on('$destroy', () => {
            $$window.off('scroll', _checkScroll);
            elem.off('ready', _checkScroll);
            $$window.off('resize', _checkScroll);
            // removeScopeEventListener();
        });
        scope.checkAssets = _checkScroll;
    };

    return {
        restrict: 'C',
        priority: 0,
        link: link
    };
}

angular
    .module('maestro.directives')
    .directive('lazyAssetGrid', LazyAssetGridDirective)
    .directive('onErrorSrc', function() {
        return {
            link: function(scope, element, attrs) {
                element.bind('error', function() {
                    attrs.$set(
                        'src',
                        'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs='
                    );
                    element.addClass('no-src');
                });
            }
        };
    });
