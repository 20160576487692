import { isFunction } from 'lodash';
import { BaseService } from './baseService';

class LocalWebsocketMetricsStorage {
    getPreviousAppUptimeMs() {
        const previous = this._get('app-uptime-ms');
        return previous || 0;
    }

    setPreviousAppUptimeMs(value) {
        this._set('app-uptime-ms', value);
    }

    getPreviousWsUptimeMs() {
        const previous = this._get('ws-uptime-ms');
        return previous || 0;
    }

    setPreviousWsUptimeMs(value) {
        this._set('ws-uptime-ms', value);
    }

    _get(metricName) {
        const stored = localStorage.getItem(metricName);
        return Number.parseInt(stored);
    }

    _set(metricName, value) {
        localStorage.setItem(metricName, value);
    }
}

export default class WebsocketConnectionReportingService extends BaseService {
    /** @ngInject */
    constructor(EID, $http, PID, EVENT, signalService, websocketMetricsStorage = new LocalWebsocketMetricsStorage()) {
        super($http);

        this._pid = PID;
        this._signalProvider = signalService;
        this._eventId = EID;
        this._org = EVENT.orgName;
        this._metricsStorage = websocketMetricsStorage;

        this._unsubscribers = [];
        this._applicationUptimePeriodTimestampMs = Date.now();
        this._isConnected = false;
    }

    init() {
        this._subscribeToWebsocketEvents();

        this._schedulePeriodicUptimeReporting();

        if (this._signalProvider.isConnected) {
            this._handleConnectionEstablishment();
        }
    }

    setLiveStreamId(value) {
        this._liveStreamId = value;
    }

    dispose() {
        this._unsubscribeFromWebsocketEvents();
        clearInterval(this._reportTimeoutId);
    }

    _subscribeToWebsocketEvents() {
        this._signalProvider.addEventListener('open', () => {
            this._handleConnectionEstablishment();
        });

        [ 'close', 'timeout', 'onInitError', 'end' ].forEach(connectionErrorType => {
            const handler = ({ type, error }) => this._handleConnectionEnd(type, error);
            const unsub = this._signalProvider.addEventListener(connectionErrorType, handler);
            this._unsubscribers.push(unsub);
        });
    }

    _unsubscribeFromWebsocketEvents() {
        this._unsubscribers.forEach(unsub => {
            if (isFunction(unsub)) {
                unsub();
            }
        });
        this._unsubscribers = [];
    }

    _schedulePeriodicUptimeReporting(periodMs = 60 * 1000) {
        this._reportTimeoutId = setTimeout(async () => {
            await this._reportUptime();
            this._schedulePeriodicUptimeReporting(periodMs);
        }, periodMs);
    }

    _handleConnectionEstablishment() {
        if (this._isConnected) {
            return;
        }

        this._isConnected = true;
        this._websocketUptimePeriodStartTimestampMs = Date.now();
        this._sendReport('connection-established');
    }

    _handleConnectionEnd(reason, error) {
        this._isConnected = false;
        this._websocketUptimePeriodEndTimestampMs = Date.now();
        this._sendReport('connection-failed', { reason, error });
    }

    async _reportUptime() {
        const { appUptimeMs, wsUptimeMs } = this._buildUptimes();

        this._saveUptimes(appUptimeMs, wsUptimeMs);

        await this._sendReport('uptime', { appUptimeMs, wsUptimeMs });

        this._applicationUptimePeriodTimestampMs = Date.now();
        this._websocketUptimePeriodStartTimestampMs = Date.now();
        this._websocketUptimePeriodEndTimestampMs = undefined;
    }

    _buildUptimes() {
        const previousAppUptimeMs = this._metricsStorage.getPreviousAppUptimeMs();
        const appUptimeMsInPeriod = Date.now() - this._applicationUptimePeriodTimestampMs;
        const appUptimeMs = previousAppUptimeMs + appUptimeMsInPeriod;

        const previousWsUptimeMs = this._metricsStorage.getPreviousWsUptimeMs();
        const wsConnectedInPeriod = this._websocketUptimePeriodStartTimestampMs !== undefined;
        const wsUptimePeriodEndTimestampMs = this._websocketUptimePeriodEndTimestampMs === undefined ? Date.now() : this._websocketUptimePeriodEndTimestampMs;
        const wsUptimeMsInPeriod = wsConnectedInPeriod ? (wsUptimePeriodEndTimestampMs - this._websocketUptimePeriodStartTimestampMs) : 0;
        const wsUptimeMs = previousWsUptimeMs + wsUptimeMsInPeriod;

        return { appUptimeMs, wsUptimeMs };
    }

    _saveUptimes(appUptimeMs, wsUptimeMs) {
        this._metricsStorage.setPreviousAppUptimeMs(appUptimeMs);
        this._metricsStorage.setPreviousWsUptimeMs(wsUptimeMs);
    }

    async _sendReport(action, payload) {
        const context = {
            userId: this._pid,
            org: this._org,
            liveStreamId: this._liveStreamId
        };

        const url = `/api/v1/eid/${this._eventId}/log/websocket-connection-metrics`;

        try {
            await this.postWithRetries(url, { action, context, payload }, 0);
        } catch (err) {
            console.error('[WebsocketConnectionReporting] Failed to send report', err);
        }
    }
}
